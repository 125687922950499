<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12">
        <v-alert color="red lighten-1" type="error" dark v-if="loginAlert">
          {{ loginAlert }}
        </v-alert></v-col
      >
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-card>
            <v-card-title class="justify-center">Login</v-card-title>
            <v-card-text>
              <v-form ref="form" @submit.prevent="handleLogin" lazy-validation>
                <v-text-field
                  v-model="credentials.email"
                  :rules="rules.email"
                  label="Email"
                  required
                  @change="loginAlert = null"
                ></v-text-field>
                <v-text-field
                  v-model="credentials.password"
                  type="password"
                  :rules="[(v) => !!v || '']"
                  label="Password"
                  required
                  @change="loginAlert = null"
                ></v-text-field>
                <v-card-actions class="justify-center">
                  <v-btn
                    type="submit"
                    :disabled="!credentials.email || !credentials.password"
                    color="primary"
                  >
                    Login
                  </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn color="grey" text to="/password/recover">
                    Forgot Password ?
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import usersService from "@/services";
import consts from "@/consts";

export default {
  data: () => ({
    rules: consts.INPUT_RULES,
    credentials: {
      email: "",
      password: "",
    },
    loginAlert: null,
  }),
  created() {},
  methods: {
    handleLogin() {
      usersService
        .login(this.credentials.email, this.credentials.password)
        .then(() => {
          this.loginAlert = null;
          this.$store.commit("updateCurrentUser");
          this.$router.push("/");
        })
        .catch((e) => {
          if (e.response.status === 403) {
            this.loginAlert = e.response.data.detail;
          } else {
            this.loginAlert = "unknown error";
          }
        });
    },
  },
};
</script>
